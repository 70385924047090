<template>
  <div class="p-3 pb-5 mb-5">
    <div class="d-flex align-items-center">
      <Organization class="mr-2"/>
      <p class="robo-24-500 txt-pri mb-0">Hội chẩn Hội trường</p>
    </div>
    <div class="my-4">
      <p class="robo-24-500 txt-black">Tạo phòng hội chẩn Hội trường</p>
      <div
        class="d-flex align-items-center txt-grey-600 cursor-pointer"
        @click="goToList"
      >
        <Back />
        <span>Quay lại danh sách</span>
      </div>
    </div>
    <div class="mb-5">
      <div class="row mb-3">
        <div class="col-lg-4 col-md-5 col-xl-3">
          <label class="robo-18-400 required mt-1 txt-black">Tên phòng</label>
        </div>
        <div class="col-lg-8 col-md-7 col-xl-9">
          <input
            type="text"
            class="form-control border radius-10"
            v-model="form.name"
          >
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-4 col-md-5 col-xl-3">
          <label class="robo-18-400 required mt-1 txt-black">Thời gian</label>
        </div>
        <div class="col-lg-8 col-md-7 col-xl-9">
          <div class="row">
            <div class="col-md-6 d-flex">
              <input
                type="date"
                class="form-control w-50 border radius-10 mr-1"
                v-model="form.date"
                :min="today"
              >
              <input
                type="time"
                class="form-control w-50 border radius-10"
                v-model="form.start_time"
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-4 col-md-5 col-xl-3">
          <label class="robo-18-400 mt-1 txt-black">Lặp lại</label>
        </div>
        <div class="col-md-6">
          <select
            v-model="form.repeat_type"
            class="form-select border radius-10"
          >
            <option :value="0">Không lặp lại</option>
            <option :value="1">Hàng ngày</option>
            <option :value="2">Hàng tuần</option>
          </select>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-4 col-md-5 col-xl-3">
          <label class="robo-18-400 mt-1 txt-black">Địa điểm</label>
        </div>
        <div class="col-lg-8 col-md-7 col-xl-9">
          <input
            type="text"
            class="form-control border radius-10"
            v-model="form.location"
          >
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center py-3">
        <button
          type="button"
          class="btn btn-white bd-pri txt-pri px-5 mr-2"
          @click="goToList"
        >
          Hủy
        </button>
        <button
          type="button"
          class="btn bg-pri bd-pri text-white px-5"
          @click="onSubmit"
        >
          Lưu
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import {Organization, Back} from '@/components/Icon'
import moment from 'moment'

export default {
  name: 'OrganizationCreateMdt',
  components: {Organization, Back},
  data () {
    return {
      form: {
        name: '',
        date: '',
        start_time: null,
        end_time: '23:59:59',
        repeat_type: 0,
        location: ''
      },
      today: moment().format('YYYY-MM-DD')
    }
  },
  mounted () {
    this.form.date = this.today
  },
  computed: {
    DoctorRequest () {
      return this.$rf.getRequest('DoctorRequest')
    }
  },
  methods: {
    goToList () {
      this.$router.push({ name: 'ListMdt' })
    },
    async onSubmit () {
      const data = {
        ...this.form,
        clinic_id: this.$clinicId,
        organization_id: this.$globalOrg ? this.$globalOrg.id : this.$globalClinic.org_id
      }
      if (!data.name) {
        alert('Vui lòng nhập tên phòng hội chẩn')
        return
      }
      if (!data.date) {
        alert('Vui lòng chọn ngày diễn ra hội chẩn')
        return
      } else {
        data.date = moment(data.date).format('DD-MM-YYYY')
      }
      if (!data.start_time) {
        alert('Vui lòng thời gian bắt đầu hội chẩn')
        return
      }
      if (!data.start_time) {
        delete data.start_time
        delete data.end_time
      } else {
        data.start_time = moment(data.start_time, 'HH:mm').format('HH:mm:ss')
        data.end_time = moment(data.end_time, 'HH:mm').format('HH:mm:ss')
      }
      const res = await this.DoctorRequest.createPublicMtdRoom(data)
      console.log('res', res.data)
      await this.$router.push({ name: 'ListMdt', query: { mdtid: res.data.id } })
    }
  }
}
</script>

<style scoped>
.form-control {
  background: #fff;
  height: 42px;
  font-size: 16px;
  color: black;
}
</style>